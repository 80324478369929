'use client';
import { Tab } from '@headlessui/react';
import { Fragment, ReactNode, useEffect, useState } from 'react';

import { Text } from './Text';

type TabsProps = {
  tabs: { title: string; icon?: ReactNode; component: ReactNode }[];
};

export const Tabs = (props: TabsProps) => {
  const { tabs } = props;

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div>
      {isMounted && (
        <Tab.Group>
          <Tab.List className="mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a]">
            {tabs.map((tab) => (
              <Tab as={Fragment} key={`tab_header_${tab.title}`}>
                {({ selected }) => (
                  <button
                    className={`${
                      selected
                        ? 'border-b !border-primary text-primary !outline-none'
                        : ''
                    }  flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-primary hover:text-primary`}
                  >
                    {tab.icon && <div className="mr-2 h-5 w-5">{tab.icon}</div>}
                    <Text fontColor="inherit">{tab.title}</Text>
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((tab) => (
              <Tab.Panel key={`tab_panel_${tab.title}`}>
                {tab.component}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
};
